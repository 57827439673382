export interface CommonEventData {
  site: string;
  pageTitle: string;
  pageUrl: string;
}

interface PageViewEvent {
  name: 'page_view';
  data?: object;
}

interface FormSubmissionEvent {
  name: 'website_form_submitted';
  data: {
    formName: string;
    formData: { [key: string]: any };
  };
}

interface TelClickedEvent {
  name: 'website_tel_clicked';
  data: {
    href: string;
    phoneNumber: string;
  };
}

interface SmsClickedEvent {
  name: 'website_sms_clicked';
  data: {
    href: string;
    phoneNumber: string;
  };
}

interface EmailClickedEvent {
  name: 'website_email_clicked';
  data: {
    href: string;
    email: string;
  };
}

interface LinkClickedEvent {
  name: 'website_link_clicked';
  data: {
    href: string;
  };
}

interface CheckoutInitiatedEvent {
  name: 'website_checkout_initiated';
  // TODO: This should be a set of standardized values
  data: { [key: string]: any };
}

export type WebsiteEvent =
  | PageViewEvent
  | FormSubmissionEvent
  | TelClickedEvent
  | SmsClickedEvent
  | EmailClickedEvent
  | LinkClickedEvent
  | CheckoutInitiatedEvent;

/** @deprecated Universal Analytics has been deprecated by Google */
export enum UniversalAnalyticsEvent {
  Loaded = 'Loaded',
  ReceivedWelcomeMessage = 'Received Welcome Message',
  CustomerMessage = 'Customer Message',
  ChatbotMessage = 'Chatbot Message',
  SupportMessage = 'Support Message',
  SupportAcceptedChat = 'Support Accepted Live Chat',
  SupportMissedChat = 'Support Missed Live Chat',
  LeadCaptured = 'Lead Captured',
  WindowOpened = 'Window Opened',
  WindowClosed = 'Window Closed',
  RequestedLiveChat = 'Requested Live Chat',
  PhoneClicked = 'Phone Number Clicked',
  EmailClicked = 'Email Clicked',
  UrlClicked = 'URL Clicked',
}

/** Standard ChatBot events for Google Analytics 4 tracking */
export enum GA4Event {
  /** chatbot loads on the page */
  Loaded = 'loaded',
  /** Technical error occurred when trying to load the ChatBot:
   * - Error initializing chatbot (api) or other technical error
   * when trying to load chatbot iframe */
  LoadFailed = 'load_failed',
  /** Several reasons that chatbot shouldn't load on the page:
   * - Bot isn't allowed to load on parent window
   * - Crawler bots are detected and blocked (e.g. Googlebot)
   * - Chatbot is disabled
   * - Chatbot not found */
  LoadPrevented = 'load_prevented',
  /** Welcome Message is received by chatbot (from Dialogflow) */
  MessageReceivedWelcome = 'message_received_welcome',
  /** Chatbot responds to customer question */
  MessageReceivedAutomated = 'message_received_automated',
  /** Live Chat support sends a message */
  MessageReceivedSupport = 'message_received_support',
  /** Customer sends a message to chatbot */
  MessageSentCustomer = 'message_sent_customer',
  /** Customer requests live chat with support agent */
  WebchatRequested = 'webchat_requested',
  /** Live chat request is accepted by support agent */
  WebchatAccepted = 'webchat_accepted',
  /** Live chat request is missed by support agent */
  WebchatMissed = 'webchat_missed',
  /** Customer completes form to request SMS Chat */
  LeadCapturedSMS = 'lead_captured_sms',
  /** Customer completes form to request Live Chat */
  LeadCapturedWebchat = 'lead_captured_webchat',
  /** Chatbot window is opened */
  WindowOpened = 'window_opened',
  /** Chatbot window is closed */
  WindowClosed = 'window_closed',
  /** Customer clicked phone number link */
  ClickedPhone = 'clicked_phone',
  /** Customer clicked email address */
  ClickedEmail = 'clicked_email',
  /** Customer clicked url */
  ClickedUrl = 'clicked_url',
  /** Customer clicked on Powered By TOMIS logo */
  ClickedPoweredByTomis = 'clicked_powered_by_tomis',
  /** Customer clicked "Return to ChatBot conversation" */
  ClickedReturnToChatbot = 'clicked_return_to_chatbot',
  /** Customer clicked on "Request SMS Chat" link */
  ClickedRequestSms = 'clicked_return_to_request_sms',
  /** Customer clicked on a quick reply */
  ClickedQuickReply = 'clicked_quick_reply',
  /** Customer checked availability (all parameters are valid) */
  CheckAvailabilitySearch = 'check_availability_search',
  CheckAvailabilitySelectQuantity = 'check_availability_select_quantity',
  CheckAvailabilitySelectDate = 'check_availability_select_date',
  CheckAvailabilitySelectProduct = 'check_availability_select_product',
  CheckAvailabilityClickMoreTimes = 'check_availability_click_more_times',
  CheckAvailabilityLinkClick = 'check_availability_link_click',
  /** Error boundary was reached */
  Crashed = 'crashed',
}
