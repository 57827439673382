import 'es6-promise/auto';

import { initializeTracking, logEvent } from './tracking';
import { WebsiteEvent } from './tracking/event-types';
import { setupBot as createIframe } from './bot/setupBot';

/** Entry point for JavaScript loaded on client site, which will create the bot iframe */
function TOMIS({
  site,
  googleAnalyticsId,
}: {
  site: string;
  googleAnalyticsId?: string;
}) {
  initializeTracking(site, googleAnalyticsId);

  /**
   * These are the methods tomis-fire exposes to the website it's used in:
   */
  return {
    /**  Initializes the bot */
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async setupBot(cb: () => any) {
      // Errors will be fatal
      await createIframe(site);
    },

    /** **TODO**: Log event in BigQuery. This used to be done in Amplitude.
     *
     * NOTE: The following events are tracked automatically:
     * - page_view
     * - website_tel_clicked
     * - website_sms_clicked
     * - website_email_clicked
     * - website_link_clicked
     * The only reason to log them manually is if they happen in some non-standard way, i.e.
     * they're not from a semantic <a href="..."> element.
     */
    async logEvent(event: WebsiteEvent) {
      // TODO: Add runtime validation on the event passed to this function.
      // We should make it hard to mess up the type of events passed, otherwise
      // our data will get messy.

      return logEvent(event);
    },
  };
}

declare global {
  interface Window {
    TOMIS: typeof TOMIS;
    gaplugins: any;
    /** Gtag.js  */
    gtag: any;
    /** Data Layer used by GTM */
    dataLayer: any[];
  }
}
window.TOMIS = TOMIS;
