"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DialogflowActions = exports.DialogflowEvents = exports.UserActionType = exports.MessageType = exports.BotFeatures = exports.ConversationTags = exports.InboxWebhookActions = exports.InboxIntegration = exports.CustomerChannel = exports.InboxLiveAgent = exports.InboxNotificationSettings = exports.TranscriptTag = exports.ReservationSystem = exports.CollectionName = exports.AmplitudeEventName = exports.CloudFunction = void 0;
/**
 * Cloud Function Names
 */
/** TOMIS Cloud function names */
var CloudFunction;
(function (CloudFunction) {
    CloudFunction["Api"] = "api";
    CloudFunction["ArchiveConversations"] = "archiveConversations";
    CloudFunction["AutoCloseInboxConversations"] = "autoCloseInboxConversations";
    CloudFunction["Dialogflow"] = "dialogflow";
    CloudFunction["InboxNotifications"] = "inboxNotifications";
    CloudFunction["LocalDevInboxNotifications"] = "localDevInboxNotifications";
    CloudFunction["NewCustomerMessage"] = "newCustomerMessage";
    CloudFunction["OnCreateDialogflowFulfillment"] = "onCreateDialogflowFulfillment";
    CloudFunction["OnCreateInboxMessage"] = "onCreateInboxMessage";
    CloudFunction["OnUpdateInboxConversation"] = "onUpdateInboxConversation";
    CloudFunction["Tomis"] = "tomis";
})(CloudFunction = exports.CloudFunction || (exports.CloudFunction = {}));
/**
 * Amplitude
 */
var AmplitudeEventName;
(function (AmplitudeEventName) {
    AmplitudeEventName["PageView"] = "page_view";
    AmplitudeEventName["ChatMessageSent"] = "chat_message_sent";
    AmplitudeEventName["ChatMessageReceived"] = "chat_message_received";
    AmplitudeEventName["ChatWindowOpened"] = "chat_window_opened";
    AmplitudeEventName["ChatWindowClosed"] = "chat_window_closed";
    AmplitudeEventName["ChatMessageWebsiteLinkClicked"] = "chat_message_website_link_clicked";
    AmplitudeEventName["ChatMessagePhoneNumberClicked"] = "chat_message_phone_number_clicked";
    AmplitudeEventName["ChatMessageEmailClicked"] = "chat_message_email_clicked";
    AmplitudeEventName["ChatTomisLinkClicked"] = "chat_tomis_link_clicked";
})(AmplitudeEventName = exports.AmplitudeEventName || (exports.AmplitudeEventName = {}));
//
// Collections
//
/** Collection Names in Firebase */
var CollectionName;
(function (CollectionName) {
    CollectionName["TwilioApiConfigs"] = "TwilioApiConfigs";
    CollectionName["UserDetails"] = "UserDetails";
    CollectionName["WebSessions"] = "WebSessions";
    CollectionName["UserActions"] = "UserActions";
    CollectionName["Sites"] = "Sites";
    CollectionName["Conversations"] = "Conversations";
    CollectionName["InboxConversations"] = "InboxConversations";
    CollectionName["Messages"] = "Messages";
    CollectionName["LiveAgents"] = "LiveAgents";
    CollectionName["Leads"] = "Leads";
    CollectionName["TomisUsers"] = "TomisUsers";
    CollectionName["MobileNotifications"] = "MobileNotifications";
    CollectionName["BrowserNotifications"] = "BrowserNotifications";
    CollectionName["ExpoPushReceipts"] = "ExpoPushReceipts";
    CollectionName["ExpoPushTickets"] = "ExpoPushTickets";
    CollectionName["AutomatedTripCommunications"] = "AutomatedTripCommunications";
    CollectionName["Reminders"] = "Reminders";
    CollectionName["SMS"] = "sms";
    CollectionName["Email"] = "email";
    CollectionName["API"] = "API";
    /** TOMIS API keys to be used by external integrations (e.g. PhoneTreeSMS) */
    CollectionName["TomisApiConfigs"] = "TomisApiConfigs";
    CollectionName["InboxSettings"] = "InboxSettings";
    /** Dialogflow integrations such as Voiceflow knowledge base */
    CollectionName["DialogflowFulfillments"] = "DialogflowFulfillments";
    /** Encrypted API keys for Voiceflow & other external integrations */
    CollectionName["ExternalApiConfigs"] = "ExternalApiConfigs";
})(CollectionName = exports.CollectionName || (exports.CollectionName = {}));
/** TOMIS Reservation System Partners */
var ReservationSystem;
(function (ReservationSystem) {
    ReservationSystem["XOLA"] = "XOLA";
    ReservationSystem["Rezdy"] = "Rezdy";
    ReservationSystem["Peek"] = "Peek";
    ReservationSystem["Singenuity"] = "Singenuity";
    ReservationSystem["Fareharbor"] = "Fareharbor";
    ReservationSystem["Other"] = "";
    ReservationSystem["Flybook"] = "Flybook";
    ReservationSystem["TourCMS"] = "TourCMS";
    ReservationSystem["Zaui"] = "Zaui";
    ReservationSystem["Ventrata"] = "Ventrata";
    ReservationSystem["Checkfront"] = "Checkfront";
    ReservationSystem["Bokun"] = "Bokun";
    ReservationSystem["AdventureOffice"] = "Adventure Office";
    ReservationSystem["Arctic"] = "Arctic";
    ReservationSystem["Origin"] = "Origin";
    ReservationSystem["Anchor"] = "Anchor";
})(ReservationSystem = exports.ReservationSystem || (exports.ReservationSystem = {}));
/** Customer-facing Voiceflow Transcript tags that are used for reporting purposes.
 *
 * Will allow TOMIS to add additional tags as needed, but we
 * have standardized a few that are necessary for reporting.
 */
var TranscriptTag;
(function (TranscriptTag) {
    /** User requested to speak with a human. Not programmatically triggered, instead
     * usually controlled on client-by-client basis via Voiceflow canvas */
    TranscriptTag["Human"] = "Human";
    /** User starts check availability flow by triggering check_availability intent  */
    TranscriptTag["CheckAvailabilityStart"] = "check_availability_start";
    /** Customer completed all necessary fields and searched inside chat widget */
    TranscriptTag["CheckAvailabilitySearch"] = "check_availability_search";
    /** Marked as reviewed via Voiceflow interface or TOMIS */
    TranscriptTag["Reviewed"] = "system.reviewed";
    /** Marked as "positive" result */
    TranscriptTag["Positive"] = "positive";
    /** Marked as "negative" result */
    TranscriptTag["Negative"] = "negative";
    /** Usually applied to conversations started on app.tomis.tech,
     * or the hosted chat widget chat.tomis.tech */
    TranscriptTag["Testing"] = "testing";
})(TranscriptTag = exports.TranscriptTag || (exports.TranscriptTag = {}));
/** All Inbox Notifications will be on by default.
 * All settings are user-specific. No company-wide settings!
 * ---
 * When building this interface, I closely followed the format of
 * HubSpot notifications. I think this will give us the most
 * flexibility going forward when we decide to add notifications
 * for additional actions such as mentioning a teammate.  */
class InboxNotificationSettings {
    /** @param enabled Default `on` or `off` for all notifications */
    constructor(enabled) {
        this.desktop = {
            actions: {
                assigned: {
                    channels: [
                        "facebookMessenger",
                        "form",
                        "instagramMessenger",
                        "phone",
                        "webchat",
                        "whatsApp",
                    ],
                    enabled,
                },
                newUnassigned: {
                    channels: [
                        "facebookMessenger",
                        "form",
                        "instagramMessenger",
                        "phone",
                        "webchat",
                        "whatsApp",
                    ],
                    enabled,
                },
                reply: {
                    channels: [
                        "facebookMessenger",
                        "form",
                        "instagramMessenger",
                        "phone",
                        "webchat",
                        "whatsApp",
                    ],
                    enabled,
                },
            },
            sounds: enabled,
        };
        this.email = {
            actions: {
                assigned: {
                    channels: [
                        "facebookMessenger",
                        "form",
                        "instagramMessenger",
                        "phone",
                        "webchat",
                        "whatsApp",
                    ],
                    enabled,
                },
                newUnassigned: {
                    channels: [
                        "facebookMessenger",
                        "form",
                        "instagramMessenger",
                        "phone",
                        "webchat",
                        "whatsApp",
                    ],
                    enabled,
                },
                reply: {
                    channels: [
                        "facebookMessenger",
                        "form",
                        "instagramMessenger",
                        "phone",
                        "webchat",
                        "whatsApp",
                    ],
                    enabled,
                },
            },
        };
        this.mobile = {
            actions: {
                assigned: {
                    channels: [
                        "facebookMessenger",
                        "form",
                        "instagramMessenger",
                        "phone",
                        "webchat",
                        "whatsApp",
                    ],
                    enabled,
                },
                newUnassigned: {
                    channels: [
                        "facebookMessenger",
                        "form",
                        "instagramMessenger",
                        "phone",
                        "webchat",
                        "whatsApp",
                    ],
                    enabled,
                },
                reply: {
                    channels: [
                        "facebookMessenger",
                        "form",
                        "instagramMessenger",
                        "phone",
                        "webchat",
                        "whatsApp",
                    ],
                    enabled,
                },
            },
        };
    }
}
exports.InboxNotificationSettings = InboxNotificationSettings;
/** TOMIS employee or agent associated with a Site.
 * Includes fields that are relevant for TOMIS Unified Inbox.
 * ---
 * LiveAgent is a collection on the Firebase Site. Used primarily
 * for Inbox Notifications. Should be created when user logs
 * into a particular site for the first time on desktop. */
class InboxLiveAgent {
    /** TOMIS employee or agent associated with a Site.
     * Includes fields that are relevant for TOMIS Unified Inbox.
     * ---
     * LiveAgent is a collection on the Firebase Site. Used primarily
     * for Inbox Notifications. Should be created when user logs
     * into a particular site for the first time on desktop.
     * @param id TOMIS User ID
     * @param name TOMIS User first_name */
    constructor(id, name) {
        this.displayName = name;
        this.fcmTokens = [];
        this.tomisId = id;
        this.mostRecentActivity = new Date();
        /** All notifications are on by default! */
        this.inboxNotificationsSettings = new InboxNotificationSettings(true);
    }
}
exports.InboxLiveAgent = InboxLiveAgent;
/** Possible Customer Channels to reach TOMIS.
 * We should try to deprecate Call and SMS in
 * favor of "Phone" */
var CustomerChannel;
(function (CustomerChannel) {
    CustomerChannel["SMS"] = "sms";
    CustomerChannel["WebsiteChatbot"] = "websiteChatbot";
    CustomerChannel["FacebookMessenger"] = "facebookMessenger";
    CustomerChannel["Unknown"] = "unknown";
    CustomerChannel["Call"] = "call";
    CustomerChannel["WhatsApp"] = "whatsApp";
    CustomerChannel["Phone"] = "phone";
    CustomerChannel["Instagram"] = "instagramMessenger";
})(CustomerChannel = exports.CustomerChannel || (exports.CustomerChannel = {}));
/** External integrations that are supported by the TOMIS Inbox.
 *
 * Integration names will be included in InboxConversation document IDs,
 * which shows in the Inbox URL. For that reason, don't include "dialogflow"
 * or "voiceflow" in the name.
 *
 * We may decide to add external integrations for other channels in the future.
 */
var InboxIntegration;
(function (InboxIntegration) {
    InboxIntegration["DIALOGFLOW_CHATBOT"] = "tomis-v1";
    InboxIntegration["VOICEFLOW_CHATBOT"] = "tomis-v2";
})(InboxIntegration = exports.InboxIntegration || (exports.InboxIntegration = {}));
/** Webhook actions available for external integrations to subscribe to */
var InboxWebhookActions;
(function (InboxWebhookActions) {
    /** InboxConversation created */
    InboxWebhookActions["CONVERSATION_CREATED"] = "conversation_created";
    /** `assignedAgentId` has been updated in any way. This includes
     * when a user accepts a conversation, assigns to another user,
     * or unassign the conversation. */
    InboxWebhookActions["CONVERSATION_ASSIGNED"] = "conversation_assigned";
    /** `status` field has been updated (i.e. opens or closes conversation)  */
    InboxWebhookActions["CONVERSATION_STATUS"] = "conversation_status";
    /** An outgoing message was sent from TOMIS Inbox. Does NOT include internal comments (system). Also does not include incoming messages */
    InboxWebhookActions["CONVERSATION_MESSAGE_SENT"] = "conversation_message_sent";
    /** TODO: implementation requires adding a new field on the InboxConversation document to track when a user is typing.
     * One common approach in React is to use a `onChange` event handler on the input field, and then set a `isTyping` flag to `true` when the user is typing.
     * Then, we can use a `setTimeout` to set `isTyping` to `false` after X seconds. This will prevent us from sending a `conversation_user_typing` event every time a user types a letter.
     *
     * This is a little challenging to implement in webhook fashion b/c we want our clients to receive webhook in the order they were sent.
     * Webhook subscriber probably needs to look at timestamp field to determine if the latest webhook request is the most recent one.
     *
     * Also consider debouncing.
     */
    InboxWebhookActions["CONVERSATION_USER_TYPING"] = "conversation_user_typing";
})(InboxWebhookActions = exports.InboxWebhookActions || (exports.InboxWebhookActions = {}));
/** Track frequency of the most popular activities, for reporting purposes.
 *
 * This allows us to add a single field on conversations that we can query against,
 * instead of having a bunch of options fields on the conversation.
 */
var ConversationTags;
(function (ConversationTags) {
    /** Knowledge base intent is matched & webhook triggered.
     * This includes successful and unsuccessful responses. */
    ConversationTags["KnowledgeBase"] = "knowledge_base";
    /** Customer completed form to request Webchat */
    ConversationTags["RequestWebChat"] = "request_web_chat";
    /** Customer completed form to request SMS Chat */
    ConversationTags["RequestSmsChat"] = "request_sms_chat";
    /** Customer triggered `check_availability` intent in Dialogflow.
     * Customer may or may not have provided required parameters. */
    ConversationTags["CheckAvailabilityStart"] = "check_availability_start";
    /** Check Availability search is triggered (query Res System API).
     * Only happens after all parameters are provided by customer. */
    ConversationTags["CheckAvailabilitySearch"] = "check_availability_search";
    /** Customer clicked on a Quick Reply button */
    ConversationTags["ClickQuickReply"] = "click_quick_reply";
    /** Customer opened chat widget. May or may not have sent a message */
    ConversationTags["OpenChatWidget"] = "open_chat_widget";
    /** Conversation included at least one fallback message */
    ConversationTags["FallbackMessage"] = "fallback_message";
})(ConversationTags = exports.ConversationTags || (exports.ConversationTags = {}));
/** At time of conversation creation, which features did this
 * conversation have access to? */
var BotFeatures;
(function (BotFeatures) {
    BotFeatures["CheckAvailability"] = "check_availability";
    BotFeatures["WebChat"] = "web_chat";
    BotFeatures["SmsChat"] = "sms_chat";
    BotFeatures["KnowledgeBase"] = "knowledge_base";
    BotFeatures["BotIcon"] = "bot_icon";
})(BotFeatures = exports.BotFeatures || (exports.BotFeatures = {}));
var MessageType;
(function (MessageType) {
    MessageType["User"] = "user";
    /** All automated communications + agents */
    MessageType["BotAgent"] = "botAgent";
    MessageType["System"] = "system";
    MessageType["UserAction"] = "userAction";
    MessageType["Custom"] = "custom";
})(MessageType = exports.MessageType || (exports.MessageType = {}));
var UserActionType;
(function (UserActionType) {
    UserActionType["PageView"] = "pageView";
    UserActionType["LinkClick"] = "linkClick";
    UserActionType["CheckAvailability"] = "checkAvailability";
    UserActionType["SubmitForm"] = "submitForm";
    UserActionType["PhoneCall"] = "phoneCall";
})(UserActionType = exports.UserActionType || (exports.UserActionType = {}));
/** Dialogflow Event Names */
var DialogflowEvents;
(function (DialogflowEvents) {
    /** Chatbot was stumped a certain amount of times (usually 2 in a row) */
    DialogflowEvents["CONSECUTIVE_UNMATCHED_INTENT_LIMIT_REACHED"] = "CONSECUTIVE_UNMATCHED_INTENT_LIMIT_REACHED";
    /** Currently unused, but should be added to "Human" intents */
    DialogflowEvents["SPEAK_TO_HUMAN"] = "SPEAK_TO_HUMAN";
    /** Default welcome intent */
    DialogflowEvents["WELCOME"] = "WELCOME";
    DialogflowEvents["MISSED_LIVE_CHAT"] = "MISSED_LIVE_CHAT";
    /** Goes with "Live Chat / Text Us" intent */
    DialogflowEvents["REQUESTED_LIVE_CHAT"] = "REQUESTED_LIVE_CHAT";
    DialogflowEvents["WELCOME_LIVE_CHAT"] = "WELCOME_LIVE_CHAT";
    DialogflowEvents["WELCOME_SMS_LIVE_CHAT"] = "WELCOME_SMS_LIVE_CHAT";
    /** Followup intent to first knowledge base query,
     * this is used to extend the webhook timeframe */
    DialogflowEvents["KNOWLEDGE_BASE_2"] = "KNOWLEDGE_BASE_2";
    /** Knowledge Base intent */
    DialogflowEvents["KNOWLEDGE_BASE"] = "KNOWLEDGE_BASE";
    /** Triggered when knowledge base is configured & Dialogflow & KB are stumped */
    DialogflowEvents["FALLBACK_MESSAGE"] = "FALLBACK_MESSAGE";
    DialogflowEvents["CHECK_AVAILABILITY"] = "CHECK_AVAILABILITY";
    DialogflowEvents["CHECK_AVAILABILITY_SEARCH"] = "CHECK_AVAILABILITY_SEARCH";
    DialogflowEvents["WEBCHAT"] = "WEBCHAT";
    DialogflowEvents["WEBCHAT_REQUEST"] = "WEBCHAT_REQUEST";
    DialogflowEvents["CAPTURE_CUSTOMER_NAME"] = "CAPTURE_CUSTOMER_NAME";
    DialogflowEvents["CAPTURE_CONTACT_PREFERENCE_EMAIL"] = "CAPTURE_CONTACT_PREFERENCE_EMAIL";
    DialogflowEvents["CAPTURE_CONTACT_PREFERENCE_CALL"] = "CAPTURE_CONTACT_PREFERENCE_CALL";
    DialogflowEvents["CAPTURE_CONTACT_PREFERENCE_SMS"] = "CAPTURE_CONTACT_PREFERENCE_SMS";
    DialogflowEvents["CAPTURE_CONTACT_PREFERENCE_NONE"] = "CAPTURE_CONTACT_PREFERENCE_NONE";
    DialogflowEvents["CAPTURE_PHONE_NUMBER_CALL"] = "CAPTURE_PHONE_NUMBER_CALL";
    DialogflowEvents["CAPTURE_PHONE_NUMBER_SMS"] = "CAPTURE_PHONE_NUMBER_SMS";
    DialogflowEvents["CAPTURE_EMAIL_ADDRESS"] = "CAPTURE_EMAIL_ADDRESS";
})(DialogflowEvents = exports.DialogflowEvents || (exports.DialogflowEvents = {}));
/** Dialogflow Actions */
var DialogflowActions;
(function (DialogflowActions) {
    /** Human intent, or Live Chat / Text Us intent */
    DialogflowActions["SPEAK_TO_HUMAN"] = "speak_to_human";
    /** Default fallback intent (no knowledge base) */
    DialogflowActions["INPUT_UNKNOWN"] = "input.unknown";
    /** This is the Human follow up "yes". User
     * confirms they want to speak to a human. */
    DialogflowActions["SHOW_LEAD_FORM"] = "show_lead_form";
    /** Welcome message for Live Chat ONLY bots */
    DialogflowActions["WELCOME_LIVE_CHAT"] = "welcome_live_chat";
    /** Welcome message for SMS bots. In SMS only mode */
    DialogflowActions["WELCOME_SMS_LIVE_CHAT"] = "welcome_sms_live_chat";
    /** Default welcome intent */
    DialogflowActions["WELCOME"] = "input.welcome";
    /** Used for all Knowledge Base intents.
     *
     * When knowledge base is implemented it takes the place of
     * the default fallback intent (and therefore `input.unknown` action) */
    DialogflowActions["KNOWLEDGE_BASE"] = "knowledge_base";
    /** Intent to check availability is triggered */
    DialogflowActions["CHECK_AVAILABILITY"] = "check_availability";
    /** All Check Availability parameters are collected, search via res system APIs */
    DialogflowActions["CHECK_AVAILABILITY_SEARCH"] = "check_availability_search";
    /** Webchat request (customer has provided their name) */
    DialogflowActions["WEBCHAT_REQUEST"] = "webchat_request";
    /** Webchat triggered */
    DialogflowActions["WEBCHAT"] = "webchat";
})(DialogflowActions = exports.DialogflowActions || (exports.DialogflowActions = {}));
