import {
  UrlObject,
  PageViewUserAction,
  Page,
  PageView,
  UserActionType,
} from '@tomis-tech/types';

export const urlToObject = (url: URL | UrlObject): UrlObject => ({
  hash: url.hash,
  host: url.host,
  hostname: url.hostname,
  href: url.href,
  origin: url.origin,
  pathname: url.pathname,
  port: url.port,
  protocol: url.protocol,
  search: url.search,
});

export const createNewPageView = (page: Page): PageView => ({
  page,
  timeEntered: new Date(),
});

export const getPageViewUserAction = (
  pageView: PageView
): PageViewUserAction => ({
  type: UserActionType.PageView,
  timestamp: new Date(),
  data: pageView,
});

/** Used on parent window: Get GA4 client ID. Will always resolve
 * with clientId, or empty string.
 *
 * Non-vital operation (tracking purposes). Does not throw errors.
 *
 * @returns Google Analytics 4 `clientId`, or empty string */
export function getGa4ClientId(siteSlug: string): string {
  try {
    const clientId = getGA4ClientIdSessionStorage(siteSlug);
    if (clientId) {
      return clientId;
    }
    if (window.gaGlobal && typeof window.gaGlobal === 'object') {
      const id = window.gaGlobal.vid || '';
      if (id) {
        setGA4ClientIdSessionStorage(siteSlug, id);
        return id;
      } else {
        console.warn('[TOMIS ChatBot] Unable to find GA4 Client ID');
        return '';
      }
    } else {
      console.warn('[TOMIS ChatBot] Unable to find GA4 Client ID');
      return '';
    }
  } catch (error) {
    console.warn('[TOMIS ChatBot] Error getting GA4 Client ID: ', error);
    return '';
  }
}

/** Get GA4 Client ID from `sessionStorage` (site specific).
 * Returns empty string if none exists */
export const getGA4ClientIdSessionStorage = (site: string) => {
  const ga4ClientIdKey = `${site}_ga4ClientId`;
  return window.sessionStorage.getItem(ga4ClientIdKey) || '';
};

/** Set GA4 Client ID from `sessionStorage` (site specific) */
export const setGA4ClientIdSessionStorage = (
  site: string,
  clientId: string
) => {
  const ga4ClientIdKey = `${site}_ga4ClientId`;
  window.sessionStorage.setItem(ga4ClientIdKey, clientId);
};
