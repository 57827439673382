/** Google Cloud project id ("tomis-bot" or "tomis-bot-dev")  */
export const PROJECT_ID = process.env.PROJECT_ID as
  | 'tomis-bot'
  | 'tomis-bot-dev';

/** - `production` if using tomis-bot
 * - `development` if using tomis-bot-dev
 */
export const ENVIRONMENT = {
  'tomis-bot': 'production',
  'tomis-bot-dev': 'development',
}[PROJECT_ID];

/** Chatbot Version from `process.env.VERSION` */
export const { VERSION } = process.env;

/** Is node running on localhost?
 *
 * `process.env.NODE_ENV !== "production"` */
export const IS_DEV = process.env.NODE_ENV !== 'production';

export const USE_NGROK = Boolean(process.env.USE_NGROK);

/** URL used to load ChatBot iframe */
export const DEV_ORIGIN = USE_NGROK
  ? 'https://tomis.ngrok.io'
  : 'http://localhost:1234';

export const CLOUD_FUNCTIONS_BASE_URI = IS_DEV
  ? `http://localhost:5007/${PROJECT_ID}/us-central1/`
  : `https://us-central1-${PROJECT_ID}.cloudfunctions.net/`;

/** Production GA4, for internal usage. Associated with
 * GA4 property ID 189853326 */
export const TOMIS_GA4_MEASUREMENT_ID = 'G-YT7KJT8ZQC';
/** Development GA4, for internal usage. Associated with
 * GA4 property ID 373585031 */
export const TOMIS_DEV_GA4_MEASUREMENT_ID = 'G-W7MK7RTR55';

/** Load correct GA4 measurement ID based on google cloud
 * `PROJECT_ID` environment variable */
export const GA4_MEASUREMENT_ID =
  PROJECT_ID === 'tomis-bot'
    ? TOMIS_GA4_MEASUREMENT_ID
    : TOMIS_DEV_GA4_MEASUREMENT_ID;
