/** Sentry should only be used inside the iframe.
 * If Sentry is used on the parent window, it may interfere with the parent window's Sentry configuration.
 *
 * In order to use Sentry in the parent window, check out the Sentry docs:
 * <https://docs.sentry.io/platforms/javascript/guides/react/best-practices/browser-extensions/>
 */
import * as Sentry from '@sentry/react';
import { CloudFunction, CollectionName } from '@tomis-tech/types';

import { VERSION, ENVIRONMENT, IS_DEV } from '../consts';

export const Category = {
  [CloudFunction.NewCustomerMessage]: `functions.${CloudFunction.NewCustomerMessage}`,
  [CollectionName.Sites]: `firestore.${CollectionName.Sites}`,
  [CollectionName.Conversations]: `firestore.${CollectionName.Conversations}`,
  [CollectionName.Messages]: `firestore.${CollectionName.Messages}`,
  Auth: 'firebase.auth',
  IFrame: 'iframe',
  Tracking: 'tracking',
  CheckAvailability: 'check.availability',
  Twilio: 'twilio',
};
type CategoryKeys = keyof typeof Category;
type CategoryValue = typeof Category[CategoryKeys];

const IS_LOCALHOST = window.location.hostname.includes('localhost');

export const SENTRY_ENVIRONMENT =
  IS_LOCALHOST || IS_DEV ? 'local' : ENVIRONMENT;

export const initSentry = () => {
  Sentry.init({
    dsn:
      'https://f22be3ad123b4ba6a402ee8c024970cc@o141882.ingest.us.sentry.io/1406211',

    // if `false`, no events are sent to Sentry
    enabled: true,

    // Which Sentry environment are we in?
    environment: SENTRY_ENVIRONMENT,

    // Additional Sentry features
    integrations: [Sentry.replayIntegration()],

    // SKD will attempt to print out useful debugging information
    // debug: IS_LOCALHOST,

    release: `tomis-fire-web-client@${VERSION}`,

    ignoreErrors: [],
    ignoreTransactions: [],
    autoSessionTracking: true,
    sendClientReports: true,
    enableTracing: true,
    // 1.0 recommended in production
    tracesSampleRate: 1.0,

    // This sets the sample rate at 1%
    replaysSessionSampleRate: 0.01,
    // Change the sample rate to 100% when sampling sessions where errors occur.
    replaysOnErrorSampleRate: 1.0,
  });
};

interface Breadcrumb extends Sentry.Breadcrumb {
  category?: CategoryValue;
}

const logBreadcrumb = (breadcrumb: Breadcrumb) => {
  if (IS_LOCALHOST) {
    const method = {
      log: 'log',
      info: 'log',
      debug: 'log',
      warning: 'warn',
      error: 'error',
      fatal: 'error',
    }[breadcrumb.level || 'info'] as 'log' | 'warn' | 'error';

    console.groupCollapsed(breadcrumb.message);

    Object.keys(breadcrumb).forEach(key => {
      console[method](`${key}:`, breadcrumb[key]);
    });
    console.groupEnd();
  }
};

/** Log error in Sentry, plus log to console on localhost */
export const logError = (err: Error | string, breadcrumb?: Breadcrumb) => {
  if (breadcrumb) {
    addBreadcrumb(breadcrumb);
  }
  if (IS_LOCALHOST) {
    console.error(err);
  } else {
    Sentry.captureException(err);
  }
};

/** Add Sentry Breadcrumb, plus log to console on localhost */
export const addBreadcrumb = (breadcrumb: Breadcrumb) => {
  logBreadcrumb(breadcrumb);
  Sentry.addBreadcrumb(breadcrumb);
};
