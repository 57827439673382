type StringMap = { [key: string]: string };
declare global {
  interface Theme {
    widget: {
      closedSpace: number;
      padding: number;
    };
    colors: StringMap;
    shadows: StringMap;
  }
}

export const getTheme = (isMobile: boolean, config: any = {}): Theme => {
  const widget = {
    closedSpace: isMobile ? 0 : 24,
    padding: 10,
  };

  const colors: StringMap = {
    primary: config.primaryColor || 'rgb(42, 167, 153)',
    greenBright: 'rgb(140, 237, 140)',
    grayLightOutline: 'rgb(227, 227, 227)',
    placeholderTextColor: 'rgb(173, 173, 173)',
    link: 'rgb(0, 161, 214)',
    linkHover: 'rgb(0, 107, 143)',
  };

  const shadows: StringMap = {
    widget: `0px 0px ${widget.padding}px 0px rgba(0,0,0,0.25)`,
  };

  return {
    widget,
    colors,
    shadows,
  };
};
