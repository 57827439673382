import { PageView } from '@tomis-tech/types';

import { SITE_SLUG } from '../bot/consts';
import { DEV_ORIGIN, GA4_MEASUREMENT_ID, VERSION } from '../consts';

import { GA4Event } from './event-types';
import { STORAGE } from '../bot/iframeStorage';

/** Set Google Analytics user id (internal TOMIS tracking) */
export function setGoogleAnalyticsUserId(id: string) {
  try {
    window.gtag('set', 'user_id', id);
  } catch (error) {
    console.warn('Error setting Google Analytics `user_id`: ', error);
  }
}

/** Set `page_location` and `page_title` (internal TOMIS tracking) */
export function setGoogleAnalyticsPageview(pageview: PageView) {
  try {
    window.gtag('set', 'page_location', pageview.page.url.href);
    window.gtag('set', 'page_title', pageview.page.title);
  } catch (error) {
    console.warn(
      'Error setting Google Analytics page_location and page_title: ',
      error
    );
  }
}

/** Log GA4 event for TOMIS ChatBot property (inside iframe)
 *
 * Optionally send GA4 event to parent window.
 */
export function trackGA4Event(
  /** Name of Google Analytics event */
  eventName: GA4Event,
  /** Event parameters */
  params?: {
    [key: string]: string | number | boolean | undefined | null;
  },
  /** Send event to parent window GA4 properties (aka client) */
  sendToClient?: boolean
) {
  try {
    const eventParameters: Record<string, any> = {
      send_to: GA4_MEASUREMENT_ID,
      site: SITE_SLUG,
      conversation_id: STORAGE.conversationId,
      web_session_id: STORAGE.webSessionId,
      ...(params || {}),
    };

    window.gtag('event', eventName, eventParameters);

    if (sendToClient) {
      /** Prepend this to the event name for client trackers */
      const prepended = 'chatbot_';
      const parentWindowParams = { ...eventParameters };
      delete parentWindowParams.send_to;
      parentWindowParams.chatbot_version = VERSION;

      // send to parent window via `window.parent.postMessage`
      sendChatbotEventToParentWindow(prepended + eventName, parentWindowParams);
    }
  } catch (error) {
    console.error('[TOMIS Chatbot] Error sending GA4 event', error);
  }
}

/** Post Message for parent window to receive.
 *
 * Make sure parent window knows to listen for messages
 * from the ChatBot iframe, matching the data here.
 *
 * Not using "penpal", which allows us to send GA4 events
 * from anywhere more easily, without needing to use React context.
 */
function sendChatbotEventToParentWindow(
  eventName: string,
  params: {
    [key: string]: string | number | boolean | undefined | null;
  }
) {
  try {
    window.parent.postMessage(
      {
        event: 'chatbot_event',
        eventName,
        params,
      },
      '*'
    );
  } catch (error) {
    console.error(
      '[TOMIS Chatbot] Error sending GA4 event to parent window',
      error
    );
  }
}

/** Attempt to get all Google Analytics 4 Measurement IDs from the parent window.
 *
 * Uses a few tricks that Austin learned to get Google Analytics 4 IDs
 * from a webpage, regardless of how GA4 is installed.
 *
 * 1. Look for gtag scripts in the DOM
 * 2. Look for `_ga_` cookies
 *
 * @returns array of Google Analytics 4 Measurement IDs. Will return empty array if none.
 */
export function getGA4MeasurementIds(): string[] {
  try {
    /** Google Analytics 4 Measurement IDs */
    let measurementIds: string[] = [];

    /** Google Analytics 4 includes this script for each measurement id.
     * Even if loaded via GTM, GTM will add this script to the DOM */
    document
      .querySelectorAll(
        "script[src^='https://www.googletagmanager.com/gtag/js?id=G-']"
      )
      .forEach(function getGA4Information(script) {
        const measurementId = new URL(
          (script as HTMLScriptElement).src
        ).searchParams.get('id');
        measurementIds.push(measurementId);
      });

    if (measurementIds.length === 0) {
      // If gtag scripts aren't loaded on the site for some reason, try to get
      // GA4 Measurement IDs from cookies
      const gaCookies = document.cookie
        .split('; ')
        .filter(function getGA4(pair) {
          return pair.split('=', 1)[0].indexOf('_ga_') > -1;
        });

      if (gaCookies.length > 0) {
        const ga4MeasurementIds = gaCookies.map(
          c => `G-${c.split('_ga_')[1].split('=')[0]}`
        );
        measurementIds = ga4MeasurementIds;
      } else {
        console.warn(
          '[TOMIS Chatbot] Google Analytics 4 is not found on this website'
        );
      }
    }
    return measurementIds;
  } catch (error) {
    console.warn(
      '[TOMIS Chatbot] Error occurred when trying to search for GA4 on the site'
    );
    return [];
  }
}

/** Decorate a link for cross-domain tracking manually (GA4).
 *
 * This is used for handling clicks inside of the ChatBot. We want
 * the parent window to navigate to the URL that was clicked inside
 * the ChatBot iframe. This is impossible to do with the cross-domain
 * solutions that come with gtag.
 *
 * This is a fancy work-around to create a dummy `<a>` tag on the parent
 * domain, trigger a `'mousedown'` event (which is what gtag uses to
 * decorate cross-domain links).
 *
 * For this to work, the domain we are linking to MUST be included in
 * GA4 settings.
 *
 * Inspired by: https://stackoverflow.com/questions/73066384/manually-generate-ga-linker-parameter-with-gtag-ga4
 *
 * How to add domain to GA4 settings: https://support.google.com/analytics/answer/10071811
 *
 * @param url Entire https address
 * @returns the fully-decorated URL (UA and GA4 compatible)
 */
export function decorateCrossDomainLink(url: string) {
  const tempAnchorEl = document.createElement('a');
  tempAnchorEl.setAttribute('type', 'hidden');
  tempAnchorEl.setAttribute('href', url);
  tempAnchorEl.setAttribute('onclick', 'event.preventDefault(); return false');
  document.body.appendChild(tempAnchorEl);
  tempAnchorEl.dispatchEvent(new MouseEvent('mousedown', { bubbles: true }));
  const urlWithLinker = tempAnchorEl.href;
  tempAnchorEl.remove();
  return urlWithLinker;
}

/** Listen for Chatbot events from iframe and push to dataLayer.
 *
 * Also, push to GA4 automatically
 *
 * @param ga4MeasurementId GA4 Measurement ID
 */
export function listenForChatbotEvents(ga4MeasurementId?: string) {
  window.dataLayer = window.dataLayer || [];
  /** Create `gtag` command if client website is using GTM */
  window.gtag =
    window.gtag ||
    function gtag() {
      // eslint-disable-next-line prefer-rest-params
      window.dataLayer.push(arguments);
    };

  window.addEventListener('message', function handleChatbotEvents(event) {
    /** We only want to listen to messages from our chatbot, not other iframes
     * that may exist on the site */
    const allowedOrigins = [
      'https://tomis-bot.firebaseapp.com',
      'https://tomis-bot-dev.firebaseapp.com',
      DEV_ORIGIN,
    ];

    if (
      allowedOrigins.includes(event.origin) &&
      event.data &&
      typeof event.data === 'object' &&
      event.data.event === 'chatbot_event'
    ) {
      try {
        const { eventName, params } = event.data;

        /** If a GA4 ID is provided, use it ONLY
         * 
         * How to provide a single GA4 ID for a chatbot: 
         * 
              var tomis = TOMIS({
                site: "site-slug", 
                googleAnalyticsId: "G-XXXXXXX"
              });
              tomis.setupBot();
         */

        // If a GA4 ID is provided, use it ONLY
        if (ga4MeasurementId) {
          params.send_to = ga4MeasurementId.trim();
        } else {
          // Otherwise, send to all GA4 IDs found on the site
          params.send_to = getGA4MeasurementIds();
        }

        /** Calling `window.gtag` automatically pushes to dataLayer.
         * 
         * Example of GTM dataLayer after the gtag event: 
         * 
          dataLayer.push({
            event: "chatbot_window_opened",
            eventModel: {
              site: "site-slug",
              conversation_id: "XXXXXXX",
              web_session_id: "XXXXXX-XX-XXXX-XXXX-XXXXXXXXXX"
            },
            gtm.uniqueEventId: 6
          })
         * 
         * Therefore, event parameters can be converted to GTM variables by 
         * accessing `eventModel[paramName]`
         */
        window.gtag('event', eventName, params);

        // Don't need this code below, because `gtag` pushes to dataLayer for us
        // window.dataLayer.push({ event: eventName, ...params });
      } catch (error) {
        console.error('[TOMIS Chatbot] Could not process chatbot event', error);
      }
    }
  });
}
