/** Convert a number or string to pixels */
export const toPx = (val: number | string): string =>
  typeof val === 'string' ? val : `${val}px`;

/** TOMIS ChatBot must be opened in an iframe.
 *
 * @throws Error if `window.self === window.top`
 */
export function requireIframe() {
  if (window.self === window.top) {
    throw new Error('TOMIS ChatBot must be opened in an iframe');
  }
}
