import { ConversationCheckAvailabilityProducts } from '@tomis-tech/types';

export const currentUrl = new URL(window.location.href);
/** site-slug from query parameters `site` */
export const SITE_SLUG = currentUrl.searchParams.get('site');
export const initialParentWidth = Number(
  currentUrl.searchParams.get('initialParentWidth')
);
export const initialParentHeight = Number(
  currentUrl.searchParams.get('initialParentHeight')
);

export const mainButtonSize = 52;

export const themeBlue = 'rgb(0,112,255)';

export const chatWindowHeaderHeight = 60;
export const chatWindowHeaderShadowHeight = 6;

export const ALL_PRODUCTS = 'All Products';
export const SEARCH = 'Search';

export const CheckAvailabilitySearchPrefix = 'Check availability search...';

export const DEFAULT_CHECK_AVAILABILITY_PRODUCTS: ConversationCheckAvailabilityProducts = {
  category: '',
  products: [],
};
