import Penpal from 'penpal';
import uuid from 'uuid/v4';

import { deviceIdKey } from './consts';
import { DEV_ORIGIN, IS_DEV, PROJECT_ID } from '../consts';
import { CrossSiteSyncMethods } from './iframe-code';

/** From parent window, get TOMIS device id from local storage.
 *
 * If doesn't exist, look for device id in iframe local storage
 * (if user has previously navigated to other sites w/ TOMIS ChatBot iframe) */
export async function getTomisDeviceIdFromParentOrIframe() {
  try {
    const siteDeviceId = localStorage.getItem(deviceIdKey);
    if (siteDeviceId) {
      /** Set iframe device id to match parent window */
      await setCrossSiteDeviceIdToIframeLocalStorage(siteDeviceId);
      return siteDeviceId;
    } else {
      const existingCrossSiteDeviceId = await getAndSyncCrossSiteDeviceId();
      if (existingCrossSiteDeviceId) {
        return existingCrossSiteDeviceId;
      }
      // create new device id and set in both parent and iframe local storage
      return createAndSyncTomisDeviceId();
    }
  } catch (error) {
    console.warn('[TOMIS ChatBot] Could not get TOMIS Device ID: ', error);
    return '';
  }
}

/** Generate new uuid, then set in parent window local storage
 * and iframe local storage. */
async function createAndSyncTomisDeviceId() {
  const newDeviceId = uuid();
  localStorage.setItem(deviceIdKey, newDeviceId);
  await setCrossSiteDeviceIdToIframeLocalStorage(newDeviceId);
  return newDeviceId;
}

/** Get TOMIS Device ID from TOMIS Bot iframe
 * and set in local storage for parent window.
 *
 * NOTE: only use this if parent window doesn't have
 * TOMIS device id in local storage */
async function getAndSyncCrossSiteDeviceId() {
  const connection = getIframeConnection();
  const {
    getDeviceIdIframeLocalStorage,
  } = (await connection.promise) as CrossSiteSyncMethods;
  const crossSiteDeviceId = await getDeviceIdIframeLocalStorage();
  connection.destroy();
  /**
   * We always want to keep local site deviceId in sync with the
   * cross-site deviceId, so if the cross-site deviceId exists, we
   * set the local site deviceId to its value.
   */
  if (crossSiteDeviceId) {
    localStorage.setItem(deviceIdKey, crossSiteDeviceId);
  }
  return crossSiteDeviceId;
}

/** Attempt to set TOMIS Device ID inside iframe.
 * If iframe local storage is blocked, this will fail silently. */
async function setCrossSiteDeviceIdToIframeLocalStorage(id: string) {
  const connection = getIframeConnection();
  const {
    setDeviceIdIframeLocalStorage,
  } = (await connection.promise) as CrossSiteSyncMethods;
  const result = await setDeviceIdIframeLocalStorage(id);
  connection.destroy();
  return result;
}

/** Create connection to chatbot iframe, used to sync device ID */
function getIframeConnection() {
  const connection = Penpal.connectToChild({
    url: IS_DEV
      ? `${DEV_ORIGIN}/src/tomis-device-id/index.html`
      : `https://${PROJECT_ID}.firebaseapp.com/tomis-device-id/index.html`,
  });
  connection.iframe.style.display = 'none';
  return connection;
}
