import endsWith from 'ramda/es/endsWith';
import { StyleOptions } from '@tomis-tech/types';

import { mainButtonSize } from './consts';
import { getTheme } from './theme';

export enum View {
  ClosedWithoutPreview = 'ClosedWithoutPreview',
  ClosedWithPreview = 'ClosedWithPreview',
  ChatWindowOpen = 'ChatWindowOpen',
}

/**
 * [width, height], [bottom, right]
 */
export type IframePosition = [
  [string | number, string | number],
  [number, number]
];

/** If width is less than 768px, assume mobile device */
export const getIsMobile = (width: number): boolean => width < 768;

/** Based on the current "View", device size, and styleOptions,
 * return the position of the iframe window like:
 * [[width, height], [bottom, right]] */
export const getIframePosition = (
  view: View,
  isMobile: boolean,
  styleOptions: StyleOptions = {
    allowFullScreenMobile: false,
    bottom: 0,
    bottomMobile: 0,
  }
): IframePosition => {
  const {
    widget: { closedSpace, padding },
  } = getTheme(isMobile);
  const { allowFullScreenMobile, bottom, bottomMobile } = styleOptions;

  const fromBottom = isMobile
    ? bottomMobile + closedSpace
    : bottom + closedSpace;

  const mainButtonSpace = mainButtonSize + padding * 2;

  switch (view) {
    case View.ClosedWithoutPreview:
      return [
        [mainButtonSpace, mainButtonSpace],
        [fromBottom, closedSpace],
      ];
    case View.ClosedWithPreview:
      return [
        [isMobile ? '100%' : 400, mainButtonSpace],
        [fromBottom, closedSpace],
      ];
    case View.ChatWindowOpen:
      return [
        [
          isMobile ? '100%' : 400,
          allowFullScreenMobile && isMobile ? '100%' : '80%',
        ],
        [0, 0],
      ];
  }
};

export const getActualSize = (
  orig: string | number,
  windowSize: number
): number => {
  if (typeof orig === 'string') {
    const int = parseInt(orig, 10);
    if (endsWith('%', orig)) {
      return (windowSize * int) / 100;
    } else if (endsWith('px', orig)) {
      return int;
    }
  } else if (typeof orig === 'number') {
    return orig;
  }
  throw new Error(
    `must be a number, or a string ending in % or px. received "${orig}"`
  );
};
