// This code runs on the parent window, to create iframe
import 'url-polyfill';
import Penpal from 'penpal';
import debounce from 'debounce';

import {
  urlToObject,
  createNewPageView,
  getGa4ClientId,
} from '../tracking/utils';
import { View, getIframePosition, getIsMobile, IframePosition } from './view';
import { DEV_ORIGIN, IS_DEV, PROJECT_ID } from '../consts';
import { BotParentPenpalMethods } from './types';
import { decorateCrossDomainLink } from '../tracking/googleAnalytics';
import { toPx } from '../utils';
import { logError } from './sentry-iframe';
import { getTomisDeviceIdFromParentOrIframe } from '../tomis-device-id';
import { parent } from '../storage';

export type BotChildPenpalMethods = {
  /** From parent window, notify iframe of parent window size change */
  updateParentWindowSize: (
    newParentWindowSize: [number, number]
  ) => Promise<void>;
  /** From parent window, set all necessary  inside the iframe */
  setIframeStorage: (items: {
    /** If doesn't exist, should be generated on parent window */
    tomisDeviceId: string;
    /** If doesn't exist, should be generated on parent window */
    webSessionId: string;
    /** Empty string is allowed to indicate no existing conversation */
    conversationId: string;
  }) => Promise<void>;
};

/** Main function to initialize chatbot (from parent window)
 *
 * If any error occurs here, it is fatal and the chatbot will not be initialized
 */
export async function setupBot(site: string): Promise<BotChildPenpalMethods> {
  /** TOMIS device ID from one of:
   * - Parent window (local storage)
   * - Iframe (local storage) = has visited another site w/ TOMIS Bot
   * - Create new = first-time visitor to any TOMIS site */
  const tomisDeviceId = await getTomisDeviceIdFromParentOrIframe();

  if (!tomisDeviceId) {
    throw new Error(
      'Unable to initialize TOMIS ChatBot - cannot get/set TOMIS device id to localStorage'
    );
  }

  const searchParameters = new URLSearchParams({
    site,
    initialParentWidth: String(window.innerWidth),
    initialParentHeight: String(window.innerHeight),
  });

  const connectionToChild = Penpal.connectToChild({
    url: IS_DEV
      ? `${DEV_ORIGIN}/src/bot/index.html?${searchParameters}`
      : `https://${PROJECT_ID}.firebaseapp.com/bot/index.html?${searchParameters}`,
    methods: {
      async getGa4ClientId() {
        return getGa4ClientId(site);
      },
      async setConversationIdSessionStorage(id) {
        try {
          return parent.setConversationIdSessionStorage(site, id);
        } catch (error) {
          console.warn(error);
        }
      },
      async getCurrentPageView() {
        return createNewPageView({
          title: document.title,
          url: urlToObject(new URL(window.location.href)),
        });
      },
      async setPosition(
        [[width, height], [bottom, right]]: IframePosition,
        view: View
      ) {
        connectionToChild.iframe.style.width = toPx(width);
        connectionToChild.iframe.style.height = toPx(height);
        connectionToChild.iframe.style.bottom = toPx(bottom);
        connectionToChild.iframe.style.right = toPx(right);

        if (view === View.ChatWindowOpen) {
          connectionToChild.iframe.className = 'open';
        } else if (view === View.ClosedWithPreview) {
          connectionToChild.iframe.className = 'closed-with-preview';
        } else if (view === View.ClosedWithoutPreview) {
          connectionToChild.iframe.className = 'closed';
        } else {
          // do nothing, keep current class
        }
      },
      goToUrl(url: string) {
        const cleanUrl =
          !url.includes('https') &&
          url.includes('http') &&
          !url.includes('localhost')
            ? /**
               * Make sure URL is https to comply with Mixed Content Security:
               * https://developers.google.com/web/fundamentals/security/prevent-mixed-content/fixing-mixed-content
               */
              url.replace('http', 'https')
            : url;

        function navigate(url: string) {
          // if iframed (AMP), navigate top window
          if (window.top !== window) {
            window.top.location.href = url;
          } else {
            /** Try to open link in a new tab.
             * If link is categorized as a popup and blocked by browser,
             * redirect the parent window */
            const popupWindow = window.open(url, '_blank');
            if (
              !popupWindow ||
              popupWindow.closed ||
              typeof popupWindow.closed === 'undefined'
            ) {
              window.location.href = url;
            }
          }
        }

        const destination = new URL(cleanUrl);

        if (destination.hostname !== window.location.hostname) {
          try {
            /** URL decorated w/ GA4 parameters */
            const ga4Decorated = decorateCrossDomainLink(destination.href);
            return navigate(ga4Decorated);
          } catch (e) {
            logError(e, {
              level: 'warning',
              message:
                'Could not decorate URL for Google Analytics 4 cross-domain tracking',
            });
          }
        }

        // if error or same domain, navigate normally
        return navigate(cleanUrl);
      },
      iframeResizeAMP(height: number) {
        window.parent.postMessage(
          {
            sentinel: 'amp',
            type: 'embed-size',
            height,
          },
          '*'
        );
      },
      async getParentWindowDimensions() {
        return {
          width: window.innerWidth,
          height: window.innerHeight,
          isMobile: getIsMobile(window.innerWidth),
        };
      },
    } as BotParentPenpalMethods,
  });

  const [initialBottom, initialRight] = getIframePosition(
    View.ClosedWithoutPreview,
    getIsMobile(window.innerWidth)
  )[1];

  connectionToChild.iframe.id = 'tomis-bot';
  connectionToChild.iframe.scrolling = 'no';
  connectionToChild.iframe.style.position = 'fixed';
  connectionToChild.iframe.style.zIndex = '9999998';
  connectionToChild.iframe.style.top = 'auto';
  connectionToChild.iframe.style.left = 'auto';
  connectionToChild.iframe.style.bottom = `${initialBottom}px`;
  connectionToChild.iframe.style.right = `${initialRight}px`;
  connectionToChild.iframe.style.border = 'none';
  connectionToChild.iframe.style.overflow = 'hidden';
  connectionToChild.iframe.style.width = '0px';
  connectionToChild.iframe.style.maxWidth = '100%';
  connectionToChild.iframe.style.height = '0px';
  connectionToChild.iframe.style.maxHeight = '100vh';
  connectionToChild.iframe.className = 'closed';

  const conversationId = parent.getConversationIdSessionStorage(site);
  const webSessionId = parent.getOrCreateWebSessionIdSessionStorage(site);

  const iframeMethods = (await connectionToChild.promise) as BotChildPenpalMethods;
  // These values be sent to iframe before chatbot can load
  await iframeMethods.setIframeStorage({
    tomisDeviceId,
    webSessionId,
    conversationId,
  });

  /** Inform chatbot iframe of parent window size, so that iframe can resize if necessary */
  const dispatchUpdateParentWindowSize = debounce(() => {
    try {
      iframeMethods.updateParentWindowSize([
        window.innerWidth,
        window.innerHeight,
      ]);
    } catch (error) {
      // iframe was likely destroyed & thus 'updateParentWindowSize' cannot be called
      console.warn(error);
      console.warn(
        "Removing window 'resize' event listener, which informs TOMIS ChatBot of updated window size. TOMIS ChatBot iframe has been removed from the page or cannot be reached."
      );
      window.removeEventListener('resize', dispatchUpdateParentWindowSize);
    }
  }, 100);

  window.addEventListener('resize', dispatchUpdateParentWindowSize);

  return iframeMethods;
}
