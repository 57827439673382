// import { urlToObject } from './utils';
import { WebsiteEvent } from './event-types';
import { listenForChatbotEvents } from './googleAnalytics';

export { trackGA4Event } from './googleAnalytics';
export { GA4Event } from './event-types';

// let site: string;

/**
 * Initialize tracking - this is called immediately by TOMIS()
 */
export async function initializeTracking(
  _site: string,
  /** Optional Google Analytics 4 Measurement ID */
  ga4MeasurementId?: string
) {
  // site = _site;
  setupAutomatedTracking();
  listenForChatbotEvents(ga4MeasurementId);
}

/** **TODO**: track event (log to BigQuery). This used to be done in Amplitude, but
 * we are going to implement a different system for tracking events.
 *
 * The following are included in every event automatically:
 * - site
 * - pageTitle
 * - pageUrl
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export async function logEvent({ name, data }: WebsiteEvent) {
  // const commonEventData = {
  //   site,
  //   pageTitle: document.title,
  //   pageUrl: urlToObject(new URL(window.location.href)),
  // };
  // TODO: Send request to backend to track event
}

/**
 * Set up automated tracking
 */
function setupAutomatedTracking() {
  /**
   * Auto detect page changes
   */
  logEvent({ name: 'page_view' });
  window.addEventListener('changestate', () => {
    logEvent({ name: 'page_view' });
  });

  /**
   * Auto detect link clicks
   */
  document.addEventListener('click', function(e) {
    const el: HTMLElement = e.target as HTMLElement;
    const anchorEl = el.closest('a');

    if (!anchorEl) return;

    const href = anchorEl.href || '';

    if (href.startsWith('tel:')) {
      logEvent({
        name: 'website_tel_clicked',
        data: {
          href,
          phoneNumber: cleanParams(href.substring(4)),
        },
      });
    } else if (href.startsWith('sms:')) {
      logEvent({
        name: 'website_sms_clicked',
        data: {
          href,
          phoneNumber: cleanParams(href.substring(4)),
        },
      });
    } else if (href.startsWith('mailto:')) {
      logEvent({
        name: 'website_email_clicked',
        data: {
          href,
          email: cleanParams(href.substring(7)),
        },
      });
    } else {
      // If none of the above match, we assume it's a normal website link
      logEvent({
        name: 'website_link_clicked',
        data: { href },
      });
    }
  });
}

/**
 * Utility function to remove any query params appended to a string
 * e.g. a "subject" param added to a "mailto:"" link
 */
function cleanParams(str: string) {
  const startOfParams = str.indexOf('?');
  if (startOfParams > -1) {
    return str.substring(0, startOfParams);
  }
  return str;
}
