/** Important global variables that are required for the ChatBot to operate.
 * Previously these were derived from iframe local and session storage, which prevented
 * bot from loading on sites that blocked third party cookies.
 *
 * Now, we are using this global variable to ensure the ChatBot loads even
 * if third party cookies are blocked.
 */
export const STORAGE = {
  /** Used to identify users across sessions and potentially across sites.
   * This will be used as the Firebase UID. */
  tomisDeviceId: '',
  /** Web Session is purely for tracking purposes */
  webSessionId: '',
  /** Conversation ID is necessary to subscribe to correct conversation */
  conversationId: '',
};
