// Manage session storage for parent window
import uuid from 'uuid/v4';

function getWebSessionIdKey(siteSlug: string) {
  return `${siteSlug}_webSessionId`;
}

/** Parent window - get Web Session ID from `sessionStorage`, or
 * create & set a new uuid to `sessionStorage` if none exists.
 *
 * @throws Error if cannot access session storage */
export const getOrCreateWebSessionIdSessionStorage = (siteSlug: string) => {
  try {
    const sessionId = window.sessionStorage.getItem(
      getWebSessionIdKey(siteSlug)
    );
    if (sessionId) {
      return sessionId;
    } else {
      const newId = uuid();
      window.sessionStorage.setItem(getWebSessionIdKey(siteSlug), newId);
      return newId;
    }
  } catch (error) {
    console.warn(
      'TOMIS ChatBot cannot load without access to browser local/session storage'
    );
    throw error;
  }
};

function getConversationIdKey(siteSlug: string) {
  return `${siteSlug}_conversationId`;
}

/** Parent window - get conversationId from `sessionStorage`.
 * If none exists, returns empty string.
 *
 * @throws Error if cannot access sessionStorage */
export const getConversationIdSessionStorage = (siteSlug: string) =>
  window.sessionStorage.getItem(getConversationIdKey(siteSlug)) || '';

/** Parent window - Set Conversation ID to `sessionStorage`
 *
 * @throws Error if cannot access sessionStorage */
export const setConversationIdSessionStorage = (
  siteSlug: string,
  conversationId: string
) => {
  window.sessionStorage.setItem(getConversationIdKey(siteSlug), conversationId);
};

/** Interact with parent window storage (from parent window) */
export const parent = {
  /** Parent window - get Web Session ID from `sessionStorage`, or
   * create & set a new uuid to `sessionStorage` if none exists.
   *
   * @throws Error if cannot access session storage */
  getOrCreateWebSessionIdSessionStorage,
  /** Parent window - get conversationId from `sessionStorage`.
   * If none exists, returns empty string.
   *
   * @throws Error if cannot access sessionStorage */
  getConversationIdSessionStorage,
  /** Parent window - Set Conversation ID to `sessionStorage`
   *
   * @throws Error if cannot access sessionStorage */
  setConversationIdSessionStorage,
};
